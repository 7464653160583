import {getFromLocalStorage} from "./functions";

const urlBack = 'https://api.dev.back.marketplace.paul-cloud.de/';

const API = (function(){
  const _token = () => getFromLocalStorage('token') || null;
  const checkStatus = res => {
    if (res.status >= 200 && res.status < 300) {
      return res.text().then(text => {
        return text ? JSON.parse(text) : {}
      });
    } else {
      return res.text().then(text => {
        if (text) {
          const {error} = JSON.parse(text);
          throw {formError: (error && Array.isArray(error) && error.length > 0) ? error[0] : "An unexpected error has occurred"};
          // throw {formError: (error && Array.isArray(error) && error.length > 0) ? error[0] : "An unexpected error has occurred"}
        } else {
          throw res.status
        }
      })
    }
  };
  return {
    get: (url) => fetch(urlBack + url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${_token()}`
        }
      }
    ).then(checkStatus),
    post: (url, body) => fetch(urlBack+url, {
        method: 'POST',
        headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${_token()}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify(body)
      }
    ).then(checkStatus),
  };

})();

export {API, urlBack};
